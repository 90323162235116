.overlayWrapper {
    position: relative;

    .saveButton {
        position: absolute;
        inset-inline-end: var(--padding-small);
        inset-block-start: var(--padding-small);
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;
        transition: all 0.3s;

        svg {
            width: var(--font-size-h2);
            stroke: white;
            stroke-width: 1.5;
            fill: rgba(0, 0, 0, 0.45);
        }
        &:hover {
            transform: scale(1.2);
        }
    }

    &[data-saved] {
        .saveButton {
            svg {
                fill: var(--red);
                stroke: rgba(0, 0, 0, 0.45);
            }
        }
    }
}

@media (prefers-reduced-motion) {
    .overlayWrapper {
        .saveButton {
            transition: none;
        }
    }
}
